/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AuthLoginParam from '../model/AuthLoginParam';
import AuthResultSuccess from '../model/AuthResultSuccess';

/**
* Auth service.
* @module api/AuthApi
* @version 1.0
*/
export default class AuthApi {

    /**
    * Constructs a new AuthApi. 
    * @alias module:api/AuthApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Login
     * @param {Object} opts Optional parameters
     * @param {module:model/AuthLoginParam} opts.authLoginParam Login parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AuthResultSuccess} and HTTP response
     */
    authLoginWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['authLoginParam'];


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = AuthResultSuccess;

      return this.apiClient.callApi(
        '/auth/login', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Login
     * @param {Object} opts Optional parameters
     * @param {module:model/AuthLoginParam} opts.authLoginParam Login parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AuthResultSuccess}
     */
    authLogin(opts) {
      return this.authLoginWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Refresh Token
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    authRefreshTokenWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/auth/refresh-token', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Refresh Token
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    authRefreshToken() {
      return this.authRefreshTokenWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
