/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import UserQueryResult from './UserQueryResult';

/**
 * The SubmissionQueryResult model module.
 * @module model/SubmissionQueryResult
 * @version 1.0
 */
class SubmissionQueryResult {
    /**
     * Constructs a new <code>SubmissionQueryResult</code>.
     * @alias module:model/SubmissionQueryResult
     */
    constructor() { 
        
        SubmissionQueryResult.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SubmissionQueryResult</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SubmissionQueryResult} obj Optional instance to populate.
     * @return {module:model/SubmissionQueryResult} The populated <code>SubmissionQueryResult</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SubmissionQueryResult();

            if (data.hasOwnProperty('applicant')) {
                obj['applicant'] = ApiClient.convertToType(data['applicant'], 'String');
            }
            if (data.hasOwnProperty('competitionId')) {
                obj['competitionId'] = ApiClient.convertToType(data['competitionId'], 'Number');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'String');
            }
            if (data.hasOwnProperty('deletedAt')) {
                obj['deletedAt'] = ApiClient.convertToType(data['deletedAt'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('filePath')) {
                obj['filePath'] = ApiClient.convertToType(data['filePath'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('submissionId')) {
                obj['submissionId'] = ApiClient.convertToType(data['submissionId'], 'Number');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'String');
            }
            if (data.hasOwnProperty('user')) {
                obj['user'] = UserQueryResult.constructFromObject(data['user']);
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {String} applicant
 */
SubmissionQueryResult.prototype['applicant'] = undefined;

/**
 * @member {Number} competitionId
 */
SubmissionQueryResult.prototype['competitionId'] = undefined;

/**
 * @member {String} createdAt
 */
SubmissionQueryResult.prototype['createdAt'] = undefined;

/**
 * @member {String} deletedAt
 */
SubmissionQueryResult.prototype['deletedAt'] = undefined;

/**
 * @member {String} description
 */
SubmissionQueryResult.prototype['description'] = undefined;

/**
 * @member {String} filePath
 */
SubmissionQueryResult.prototype['filePath'] = undefined;

/**
 * @member {String} status
 */
SubmissionQueryResult.prototype['status'] = undefined;

/**
 * @member {Number} submissionId
 */
SubmissionQueryResult.prototype['submissionId'] = undefined;

/**
 * @member {String} updatedAt
 */
SubmissionQueryResult.prototype['updatedAt'] = undefined;

/**
 * @member {module:model/UserQueryResult} user
 */
SubmissionQueryResult.prototype['user'] = undefined;

/**
 * @member {Number} userId
 */
SubmissionQueryResult.prototype['userId'] = undefined;






export default SubmissionQueryResult;

