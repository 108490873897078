import React, { Component } from 'react';
import cx from 'classnames';

import BagImage from './bag.png'
import './BagSelector.scss';

export class BagSelector extends Component {
	render() {
		const { selected, label, ...rest } = this.props;
		return (
			<div 
				className={cx({
					"BagSelector": true,
					"selected": selected
				})}
				{...rest}
			>
				<img src={BagImage} alt="plastic-bag"/>
				<p className="bag-label">{label}</p>
			</div>
		);
	}
}

export default BagSelector;