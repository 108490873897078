import React, { Component } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

import FilterGroup from '../../../../../../components/FilterGroup/FilterGroup';
import './SchoolDataPage.scss';

export class SchoolDataPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            availableSchools: ['School1', 'School2'],
            availableMonths: _.range(0, 11),
            availableBags: ['Big Waster Bag', 'Non-designated Bags/Classroom Bags'],
            dataset: _.map(_.range(0, 100), (i) => ({
                id: i,
                school: Math.random() > 0.5 ? 'School1' : 'School2',
                month: _.random(0, 11),
                paper: _.random(0, 100),
                plastic: _.random(0, 100),
                metal: _.random(0, 100),
                waste: _.random(0, 100),
            })),
            filters: {}
        }
    }

    render() {
        const { availableSchools, availableMonths, availableBags, dataset } = this.state;

        const filterList = [{
            type: 'multiSelect',
            name: 'school',
            placeholder: '(All Schools)',
            options: _.map(availableSchools, (school) => ({ label: school, value: school }))
        }, {
            type: 'multiSelect',
            name: 'month',
            placeholder: '(All Months)',
            options: _.map(availableMonths, (month) => ({ label: moment().month(month).format('MMM'), value: month }))
        }, {
            type: 'multiSelect',
            name: 'bag_type',
            placeholder: '(All Bag Types)',
            options: _.map(availableBags, (bagObj) => ({ label: bagObj, value: bagObj }))
        }];
        const columns = [{
            title: 'School',
            dataIndex: 'school',
            align: 'center',
            sorter: (a, b) => a.school.localeCompare(b.school),
        }, {
            title: 'Month',
            dataIndex: 'month',
            align: 'center',
            sorter: (a, b) => a.month - b.month,
            render: (value, record) => moment().month(value).format('MMM')
        }, {
            title: 'Total Waste (kg)',
            dataIndex: 'total_waste',
            align: 'center',
            sorter: (a, b) => a.paper - b.paper,
            render: (value, record) => record.paper + record.plastic + record.metal + record.waste
        }, {
            title: 'Paper (kg)',
            dataIndex: 'paper',
            align: 'center',
            sorter: (a, b) => a.paper - b.paper,
            render: (value, record) => _.isUndefined(value) ? 0 : numeral(value).format('0,0.[00]')
        }, {
            title: 'Plastic (kg)',
            dataIndex: 'plastic',
            align: 'center',
            sorter: (a, b) => a.plastic - b.plastic,
            render: (value, record) => _.isUndefined(value) ? 0 : numeral(value).format('0,0.[00]')
        }, {
            title: 'Metal (kg)',
            dataIndex: 'metal',
            align: 'center',
            sorter: (a, b) => a.metal - b.metal,
            render: (value, record) => _.isUndefined(value) ? 0 : numeral(value).format('0,0.[00]')
        }, {
            title: 'Waste (kg)',
            dataIndex: 'waste',
            align: 'center',
            sorter: (a, b) => a.waste - b.waste,
            render: (value, record) => _.isUndefined(value) ? 0 : numeral(value).format('0,0.[00]')
        }];

        return (
            <div className="SchoolDataPage">
                <div className="table-section">
                    <h3>View School Data</h3>
                    <FilterGroup
                        filterList={filterList}
                        onChange={(filters) => this.setState({ filters })}
                    />
                    <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={dataset}
                    />
                </div>
            </div>
        );
    }
}

export default SchoolDataPage;