/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The UserUpdateUserParam model module.
 * @module model/UserUpdateUserParam
 * @version 1.0
 */
class UserUpdateUserParam {
    /**
     * Constructs a new <code>UserUpdateUserParam</code>.
     * @alias module:model/UserUpdateUserParam
     */
    constructor() { 
        
        UserUpdateUserParam.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>UserUpdateUserParam</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserUpdateUserParam} obj Optional instance to populate.
     * @return {module:model/UserUpdateUserParam} The populated <code>UserUpdateUserParam</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserUpdateUserParam();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('orgId')) {
                obj['orgId'] = ApiClient.convertToType(data['orgId'], 'Number');
            }
            if (data.hasOwnProperty('parentUserId')) {
                obj['parentUserId'] = ApiClient.convertToType(data['parentUserId'], 'Number');
            }
            if (data.hasOwnProperty('password')) {
                obj['password'] = ApiClient.convertToType(data['password'], 'String');
            }
            if (data.hasOwnProperty('role')) {
                obj['role'] = ApiClient.convertToType(data['role'], 'String');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} name
 */
UserUpdateUserParam.prototype['name'] = undefined;

/**
 * @member {Number} orgId
 */
UserUpdateUserParam.prototype['orgId'] = undefined;

/**
 * @member {Number} parentUserId
 */
UserUpdateUserParam.prototype['parentUserId'] = undefined;

/**
 * @member {String} password
 */
UserUpdateUserParam.prototype['password'] = undefined;

/**
 * @member {String} role
 */
UserUpdateUserParam.prototype['role'] = undefined;

/**
 * @member {String} username
 */
UserUpdateUserParam.prototype['username'] = undefined;






export default UserUpdateUserParam;

