import React, { Component } from 'react';
import cx from 'classnames';
import _ from 'lodash';

import CartoonAnswerButton from '../../../../../../components/CartoonAnswerButton/CartoonAnswerButton'

import './GamingInterface.scss';

import scripts from './translations.js';

export class GamingInterface extends Component {
    render() {
        const { langCode, question, selectedAnswerId, onAnswerSelect, onAnswerSubmit, isAnswerSubmitted} = this.props;

        return (
            <div className="GamingInterface">
				<div className="question-box">
					<p>{question.translation[langCode].question}</p>
					{ !_.isEmpty(question.translation[langCode].explanation) && isAnswerSubmitted && <p className="answer-explanation">{langCode =="en" ? "Explanation:": "題解："} {question.translation[langCode].explanation}</p> }
				</div>
				<div className="answers-section">
					{
						_.map(_.range(0, 4), (i) => (
							<CartoonAnswerButton 
								key={`answer-${i + 1}`} 
								answerIdx={i}
								answerText={question.answers[i].translation[langCode].answer}
								selected={selectedAnswerId === question.answers[i].id}
								isAnswerSubmitted={isAnswerSubmitted}
								isCorrect={question.answers[i].isCorrect}
								onClick={(e) => onAnswerSelect(question.answers[i].id)}
							/>
						))
					}
				</div>
				<div 
					className={cx({
						"submit-section": true,
						"disabled": selectedAnswerId === undefined
					})}
				>
					<button className={cx({
						"bw-btn": true,
						"submit-btn": true,
						"submitted": isAnswerSubmitted
					})} onClick={(e) => onAnswerSubmit(e)}>
						{ isAnswerSubmitted ? scripts[langCode].next : scripts[langCode].confirm }
					</button>
				</div>
			</div>
        );
    }
}

GamingInterface.defaultProps = {
    question: '',
    answers: ['', '', '', ''],
    onAnswerSelect: () => {}
}

export default GamingInterface;