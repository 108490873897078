export default {
	'en': {
		'endGameMessage': (x) => `Congrats, you made $${x} today.`,
		'endGameDayLimitExceedMessage': 'You have reached the day limit, no extra points will be earned today.',
		'logout': 'Log Out',
		'playAgain': 'Play Again',
	},
	'tc': {
		'endGameMessage': (x) => `恭喜，你今天賺取了 $${x}。`,
        'endGameDayLimitExceedMessage': '今天遊戲次數已達到上限，不會再賺取積分。',
		'logout': '登出',
		'playAgain': '再玩一次',
	}
}