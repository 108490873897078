import React, { Component } from 'react';
import { DatePicker, Form, Icon, Input, Select, Table } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import HelperScheduleFormModal from './components/HelperScheduleFormModal/HelperScheduleFormModal';
import './EditHelperSchedulePage.scss';

const Option = Select.Option;
const FormItem = Form.Item;

export class EditHelperSchedulePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            availableSchools: ['School1', 'School2'],
            dataset: _.map(_.range(0, 2), (i) => ({
                id: i,
                from: moment().format(),
                to: moment().add(1, 'years').format(),
                helper: 'Helper 1',
                school: ['元朗朗屏邨惠州學校', '香海正覺蓮社佛教陳式宏學校', '大角嘴天主教小學(海帆道)'][i],
            })),
            isHelperScheduleFormModalVisible: false,
            recordId: undefined
        }
    }

    render() {
        const { form } = this.props;
        const { availableSchools, correspodingPerson, coordinator, dataset, isHelperScheduleFormModalVisible, recordId } = this.state;
        const { getFieldDecorator } = form;

        const columns = [{
            title: '',
            dataIndex: 'edit',
            key: 'edit',
            width: 50,
            align: 'center',
            render: (text, record) => !record.is_confirmed && (<Icon className="icon-btn" type="edit" onClick={() => this.setState({ isHelperScheduleFormModalVisible: true, recordId: record.id })} />)
        }, {
            width: 150,
            title: 'From',
            dataIndex: 'from',
            align: 'center',
            render: (date, record) => moment(date).format('YYYY-MM-DD')
        }, {
            width: 150,
            title: 'To',
            dataIndex: 'to',
            align: 'center',
            render: (date, record) => moment(date).format('YYYY-MM-DD')
        }, {
            width: 200,
            title: 'Helper',
            dataIndex: 'helper',
            align: 'center',
        }, {
            title: 'School',
            dataIndex: 'school',
            align: 'center'
        }, {
            title: '',
            dataIndex: 'delete',
            key: 'delete',
            width: 50,
            align: 'center',
            render: (text, record) => (<Icon className="icon-btn remove-btn" type="close" onClick={() => this.setState({ recordId: record.id, isHelperScheduleFormModalVisible: true })} />)
        }];

        const schoolOptions = _.map(availableSchools, (school) => <Option key={school} value={school}>{school}</Option>);

        return (
            <div className="EditHelperSchedulePage">
                <h3>Edit Helper's Schedule</h3>
                <div className="contact-section">
                    <p>
                        Coresponding Person: 
                        <Input value={correspodingPerson} onChange={e => this.setState({ correspodingPerson: e.target.value })}/>
                    </p>
                    <p>
                        Coordinator:
                        <Input value={coordinator} onChange={e => this.setState({ coordinator: e.target.value })}/>
                    </p>
                    <button className="bw-btn">Update</button>
                </div>
                <div className="input-section">
                    <Form layout="inline" onSubmit={(e) => this.handleSubmit(e)}>
                        <FormItem>
                            {getFieldDecorator('from', {
                                rules: [{ required: true }],
                            })(
                                <DatePicker 
                                    placeholder="From" 
                                />
                            )}
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator('to', {
                                rules: [{ required: true }],
                            })(
                                <DatePicker 
                                    placeholder="To" 
                                />
                            )}
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator('helper', {
                                rules: [{ required: true }],
                            })(
                                <Input 
                                    placeholder="Helper" 
                                />
                            )}
                        </FormItem>
                        <FormItem>
                            {getFieldDecorator('school', {
                                rules: [{ required: true }],
                            })(
                                <Select 
                                    placeholder="School" 
                                >
                                    {schoolOptions}
                                </Select>
                            )}
                        </FormItem>
                        <FormItem>
                            <button className="bw-btn">Add</button>
                        </FormItem>
                    </Form>
                </div>
                <div className="table-section">
                    <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={dataset}
                    />
                </div>
                <HelperScheduleFormModal
                    visible={isHelperScheduleFormModalVisible}
                    recordId={recordId}
                    onCancel={(e) => this.setState({ isHelperScheduleFormModalVisible: false })}
                    afterSubmit={() => {console.log('refetch')}}
                />
            </div>
        );
    }
}

const WrappedEditHelperSchedulePage = Form.create()(EditHelperSchedulePage);
export default WrappedEditHelperSchedulePage;