/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import UserOutputUser from '../model/UserOutputUser';
import UserOutputUsers from '../model/UserOutputUsers';
import UtilHttpErrorBody from '../model/UtilHttpErrorBody';

/**
* OrgUser service.
* @module api/OrgUserApi
* @version 1.0
*/
export default class OrgUserApi {

    /**
    * Constructs a new OrgUserApi. 
    * @alias module:api/OrgUserApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Get user information
     * @param {Number} orgId org id
     * @param {Number} userId user id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserOutputUser} and HTTP response
     */
    orgUserWithHttpInfo(orgId, userId) {
      let postBody = null;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling orgUser");
      }

      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling orgUser");
      }


      let pathParams = {
        'orgId': orgId,
        'userId': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = UserOutputUser;

      return this.apiClient.callApi(
        '/org/{orgId}/user/{userId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get user information
     * @param {Number} orgId org id
     * @param {Number} userId user id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserOutputUser}
     */
    orgUser(orgId, userId) {
      return this.orgUserWithHttpInfo(orgId, userId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all users
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserOutputUsers} and HTTP response
     */
    orgUsersWithHttpInfo(orgId) {
      let postBody = null;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling orgUsers");
      }


      let pathParams = {
        'orgId': orgId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = UserOutputUsers;

      return this.apiClient.callApi(
        '/org/{orgId}/user', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all users
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserOutputUsers}
     */
    orgUsers(orgId) {
      return this.orgUsersWithHttpInfo(orgId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
