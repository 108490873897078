import React, { Component } from 'react';
import { DatePicker, Form, InputNumber, Modal, Select } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import './BagFormModal.scss';

const FormItem = Form.Item;
const Option = Select.Option;

export class BagFormModal extends Component {
    handleSubmit(e) {
        const { afterSubmit, form } = this.props;
        e.preventDefault();

        form.validateFields((err, values) => {
            if (err) {
                console.log(err);
            } else {
                console.log(values);
                afterSubmit();
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { form, selectedRecord } = this.props;

        if (!_.isEqual(selectedRecord, prevProps.selectedRecord)) {
            const { date, bag_type, quantity } = selectedRecord;
            form.setFieldsValue({ date: moment(date), bag_type, quantity });
        }
    }

    render() {
        const { form, availableBags, ...props } = this.props;
        const { getFieldDecorator } = form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const bagOptions = _.map(availableBags, (bag) => <Option key={bag} value={bag}>{bag}</Option>);

        return (
            <Modal
                className="BagFormModal"
                width="70%"
                title={null}
                footer={null}
                {...props}
            >
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                    <FormItem
                        {...formItemLayout}
                        label="Date"
                    >
                        {getFieldDecorator('date')(
                            <DatePicker />
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="Bag Type"
                    >
                        {getFieldDecorator('bag_type')(
                            <Select>
                                {bagOptions}
                            </Select>
                        )}
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label="Quantity"
                    >
                        {getFieldDecorator('quantity')(
                            <InputNumber min={1} />
                        )}
                    </FormItem>
                    <button type="submit">Submit</button>
                </Form>
            </Modal>
        );
    }
}

BagFormModal.defaultProps = {
    availableBags: ['3L', '5L', '10L', '20L']
}

const WrappedBagFormModal = Form.create()(BagFormModal);

export default WrappedBagFormModal;