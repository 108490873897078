import React, { Component } from 'react';
import { connect } from 'react-redux';

import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

import './DashboardPage.scss';

export class DashboardPage extends Component {
    render() {
        const { children } = this.props;

        return (
            <div className="DashboardPage">
                <Header isMultiLingual={true}/>
                <div className="container">
                    {children}
                </div>
                <Footer/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isAdmin: state.dashboard.isAdmin
})

export default connect(mapStateToProps)(DashboardPage);