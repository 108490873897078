import {
    UPDATE_LANGUAGE_CODE,
} from '../../actionTypes';

const INITIAL_STATE = {
    langCode: 'en'
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_LANGUAGE_CODE:
            return {
                ...state,
                langCode: action.payload
            };
        default:
            return state;
    }
}