import React, { Component } from 'react';
import { Tooltip } from 'antd';
import numeral from 'numeral';

import getPercentage from '../../utils/getPercentage';
import './DividedBar.scss';

export default class DividedBar extends Component {
    render() {
        const { questionLabel, questionPts, currencyBalanceLabel, currencyBalancePts, maxTotalPts } = this.props;
        const totalPts = currencyBalancePts;
        const totalBarWidth = getPercentage(totalPts, 0, maxTotalPts);
        let leftBarWidth = questionPts / totalPts;
        let rightBarWidth = (currencyBalancePts - questionPts) / totalPts;

        if (leftBarWidth > 0.95) {
            leftBarWidth = 0.95;
            rightBarWidth = 0.05;
		}

        if (rightBarWidth > 0.95) {
            rightBarWidth = 0.95;
            leftBarWidth = 0.05;
        }

        const tooltipContent = (
        	<div className="DividedBarTooltipContent">
        		<div className="tooltip-row">
        			<span className="tooltip-green"></span>
	        		<div className="tooltip-label">{questionLabel}(HKD$):</div>
	        		<div className="tooltip-value">{numeral(questionPts).format('0.00')}</div>
        		</div>
        		<div className="tooltip-row">
        			<span className="tooltip-red"></span>
	        		<div className="tooltip-label">{currencyBalanceLabel}(HKD$):</div>
	        		<div className="tooltip-value">{numeral(currencyBalancePts - questionPts).format('0.00')}</div>
        		</div>
        	</div>
        );

        return (
            <Tooltip title={tooltipContent} overlayStyle={{ width: 500 }}>
	        	<div className="DividedBar" style={{ width: `${totalBarWidth * 100}%` }}>
	        		<div 
	        			className="left-bar"
	        			style={{ width: `${leftBarWidth * 100}%` }}
	        		>
                        {leftBarWidth >= 0.25 ? numeral(questionPts).format('0.00') : '\u00A0'}
                    </div>
	        		<div 
	        			className="right-bar"
	        			style={{ width: `${rightBarWidth * 100}%` }}
	        		>
                        {rightBarWidth >= 0.25 ? numeral(currencyBalancePts - questionPts).format('0.00') : '\u00A0'}
                    </div>
	        	</div>
        	</Tooltip>
        );
    }
}

DividedBar.defaultProps = {
	questionPts: 0,
	currencyBalancePts: 0,
	maxTotalPts: 0
}