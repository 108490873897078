import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import Char1Img from './char-1.svg';
import Char2Img from './char-2.svg';
import './FullWidthCartoonButton.scss'

export class FullWidthCartoonButton extends Component {
    render() {
        const { history, btnText, targetUrl, onClick, backgroundColor} = this.props;
        let style = {};

        if(backgroundColor){
            style.backgroundColor = backgroundColor
        }

        return (
            <button style={style} className="FullWidthCartoonButton" onClick={(e) => targetUrl ? history.push(targetUrl) : onClick()}>
				<img src={Char2Img} alt=""/>
				{btnText}
				<img src={Char1Img} alt=""/>
			</button>
        );
    }
}

export default withRouter(FullWidthCartoonButton);