import React from 'react';
import {
    Router,
    Route,
    Redirect,
    Switch
} from 'react-router-dom';
import createBrowserHistory from 'history/createBrowserHistory';

import SchoolRoute from './routes/SchoolRoute/SchoolRoute';
import ClassRoute from './routes/ClassRoute/ClassRoute';

import BlankPage from './scenes/BlankPage/BlankPage';

import WebInterface from './scenes/WebInterface/WebInterface';
import WebLandingPage from './scenes/WebInterface/scenes/LandingPage/LandingPage';
import WebLoginPage from './scenes/WebInterface/scenes/LoginPage/LoginPage';
import WebGamingPage from './scenes/WebInterface/scenes/GamingPage/GamingPage';
import StudentHelperPanelPage from './scenes/WebInterface/scenes/StudentHelperPanelPage/StudentHelperPanelPage';

import DashboardPage from './scenes/DashboardPage/DashboardPage';
import DashboardLoginPage from './scenes/DashboardPage/scenes/LoginPage/LoginPage';
import DashboardHelperPage from './scenes/DashboardPage/scenes/HelperPage/HelperPage';
import DashboardAdminPage from './scenes/DashboardPage/scenes/AdminPage/AdminPage';

const history = createBrowserHistory();

const webSection = ({ match, ...rest }) => (
    <WebInterface match={match} {...rest}>
		<Switch>
			<Route exact path={`${match.url}/`} component={WebLandingPage}/>
			<Route exact path={`${match.url}/login`} component={WebLoginPage}/>
            <Route exact path={`${match.url}/helper-login`} component={WebLoginPage}/>
            <ClassRoute exact path={`${match.url}/game`} component={WebGamingPage}/>
            <ClassRoute exact path={`${match.url}/student-helper-panel`} component={StudentHelperPanelPage}/>
			<Redirect to={`${match.url}/`}/>
		</Switch>
	</WebInterface>
);

const dashboardSection = ({ match, ...rest }) => (
    <DashboardPage match={match} {...rest}>
        <Switch>
            <Route exact path={`${match.url}/login`} component={DashboardLoginPage}/>
            <Route exact path={`${match.url}/helper`} component={DashboardHelperPage}/>
            <Route exact path={`${match.url}/admin`} component={DashboardAdminPage}/>
            <Redirect to={`${match.url}/login`}/>
        </Switch>
    </DashboardPage>
);

const AppRouter = () => (
    <Router history={history}>
    	<Switch>
    		<SchoolRoute path="/web/:schoolId" component={webSection}/>
    		<SchoolRoute path="/kiosk/:schoolId" component={webSection}/>
            <Route path="/dashboard" component={dashboardSection}/>
            <Route path="/" component={BlankPage} />
			<Redirect to="/"/>
        </Switch>
    </Router>
);

export default AppRouter;