import React, { Component } from 'react';
import { connect } from 'react-redux';
import { matchPath, Route, Redirect } from 'react-router-dom';
import _ from 'lodash';

export class ClassRoute extends Component {
    render() {
        const { selectedClass, component, ...rest } = this.props;
        const match = matchPath(rest.location.pathname, {
            path: '/:device/:schoolId/:page',
            exact: true,
            strict: false
        });

        return (
            <Route 
                {...rest }
                render={ props => !_.isEmpty(selectedClass) ? React.createElement(component, props) : <Redirect to={`/${match.params.device}/${match.params.schoolId}`}/> }
            />
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        selectedClass: state.student.class
    });
};

export default connect(mapStateToProps)(ClassRoute);