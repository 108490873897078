import React, { Component } from 'react';
import { DatePicker, Form, Input, Modal, Select } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import './HelperScheduleFormModal.scss';

const FormItem = Form.Item;
const Option = Select.Option;

export class HelperScheduleFormModal extends Component {
    handleSubmit(e) {
        const { afterSubmit, form } = this.props;
        e.preventDefault();

        form.validateFields((err, values) => {
            if (err) {
                console.log(err);
            } else {
                console.log(values);
                afterSubmit();
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { form, selectedRecord } = this.props;

        if (!_.isEqual(selectedRecord, prevProps.selectedRecord)) {
            const { date, bag_type, quantity } = selectedRecord;
            form.setFieldsValue({ date: moment(date), bag_type, quantity });
        }
    }

    render() {
        const { form, availableSchools, ...props } = this.props;
        const { getFieldDecorator } = form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const schoolOptions = _.map(availableSchools, (school) => <Option key={school} value={school}>{school}</Option>);

        return (
            <Modal
                className="HelperScheduleFormModal"
                width="70%"
                title={null}
                footer={null}
                {...props}
            >
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                    <FormItem
                    	{...formItemLayout}
                    	label="From"
                    >
                        {getFieldDecorator('from', {
                            rules: [{ required: true }],
                        })(
                            <DatePicker 
                                placeholder="From" 
                            />
                        )}
                    </FormItem>
                    <FormItem
                    	{...formItemLayout}
                    	label="To"
                    >
                        {getFieldDecorator('to', {
                            rules: [{ required: true }],
                        })(
                            <DatePicker 
                                placeholder="To" 
                            />
                        )}
                    </FormItem>
                    <FormItem
                    	{...formItemLayout}
                    	label="Helper"
                    >
                        {getFieldDecorator('helper', {
                            rules: [{ required: true }],
                        })(
                            <Input 
                                placeholder="Helper" 
                            />
                        )}
                    </FormItem>
                    <FormItem
                    	{...formItemLayout}
                    	label="School"
                    >
                        {getFieldDecorator('school', {
                            rules: [{ required: true }],
                        })(
                            <Select 
                                placeholder="School" 
                            >
                                {schoolOptions}
                            </Select>
                        )}
                    </FormItem>
                    <button type="submit">Update</button>
                </Form>
            </Modal>
        );
    }
}

HelperScheduleFormModal.defaultProps = {
    availableSchools: ['School1', 'School2']
}

const WrappedHelperScheduleFormModal = Form.create()(HelperScheduleFormModal);

export default WrappedHelperScheduleFormModal;