import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import _ from 'lodash';

import * as actions from '../../../../services/dashboard/actions';
import SelectSchoolPage from './scenes/SelectSchoolPage/SelectSchoolPage';
import SelectActionPage from '../../components/SelectActionPage/SelectActionPage';
import BagUsagePage from './scenes/BagUsagePage/BagUsagePage';
import WasteUsagePage from './scenes/WasteUsagePage/WasteUsagePage';
import BagRefundPage from './scenes/BagRefundPage/BagRefundPage';
import HelperSchedulePage from './scenes/HelperSchedulePage/HelperSchedulePage';
import './HelperPage.scss';

export class HelperPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            availableSchools: [{
                id: 1,
                name: '元朗朗屏邨惠州學校'
            }, {
                id: 2,
                name: '香海正覺蓮社佛教陳式宏學校'
            }, {
                id: 3,
                name: '大角嘴天主教小學(海帆道)'
            }],
            availableActions: [
                'Input/Review Bag Usage',
                'Input/Review Waste & Recyclables',
                'Input/Review Refunded Bags',
                `Helper's Schedule`
            ],
            selectedSchool: undefined,
            selectedAction: undefined
        }
    }

    redirectToLoginPage() {
        const { history } = this.props;

        history.push('/dashboard/login');
    }

    handleLogout(e) {
        const { updateDashboardUserInfo } = this.props;

        updateDashboardUserInfo({ isAdmin: false });
        this.redirectToLoginPage();
    }

    handleBack(e) {
        const { selectedAction } = this.state;

        if (_.isUndefined(selectedAction)) {
            this.setState({ selectedSchool: undefined })
        } else {
            this.setState({ selectedAction: undefined })
        }
    }

    renderPages() {
        const { availableSchools, availableActions, selectedSchool, selectedAction } = this.state;

        if (_.isUndefined(selectedSchool)) {
            return <SelectSchoolPage 
                availableSchools={availableSchools} 
                onSelectSchool={(schoolId) => this.setState({ selectedSchool: schoolId })}
            />;
        } else if (_.isUndefined(selectedAction)) {
            return <SelectActionPage
                availableActions={availableActions} 
                onSelectAction={(action) => this.setState({ selectedAction: action })}
            />
        } else {
            switch (selectedAction) {
                case 'Input/Review Waste & Recyclables':
                    return <WasteUsagePage />;
                case 'Input/Review Bag Usage':
                    return <BagUsagePage />;
                case 'Input/Review Refunded Bags':
                    return <BagRefundPage />;
                case `Helper's Schedule`:
                    return <HelperSchedulePage />;
                default:
                    return;
            }
        }
    }

    render() {
        const { selectedSchool } = this.state;

        return (
            <div className="HelperPage">
                <div className="btn-menu">
                    { !_.isUndefined(selectedSchool) && <button className="bw-btn back-btn" onClick={(e) => this.handleBack(e)}><Icon type="rollback" />Back</button> }
                    <button className="logout-btn" onClick={(e) => this.handleLogout(e)}>Logout</button>
                </div>
                {this.renderPages()}
            </div>
        );
    }
}

export default connect(null, actions)(HelperPage);