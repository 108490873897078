import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input } from 'antd';

import * as actions from '../../../../services/dashboard/actions';
import './LoginPage.scss';

// const API_URL = process.env.API_URL;
const FormItem = Form.Item;

export class LoginPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoggingIn: false
        }
    }

    handleSubmit(e) {
        const { form, history } = this.props
        // const { form, updateErrorMessage, updateUser, history } = this.props
        e.preventDefault();
        // this.setState({ isLoggingIn: true });
        // updateErrorMessage('');

        // form.validateFields((err, values) => {
        //     if (!err) {
        //         fetch(`${API_URL}/auth/login`, {
        //                 method: 'POST',
        //                 mode: 'cors',
        //                 headers: {
        //                     'Content-Type': 'application/json'
        //                 },
        //                 body: JSON.stringify(values)
        //             })
        //             .then((res) => res.json())
        //             .then((json) => {
        //                 if (json.error)
        //                     updateErrorMessage(json.error.message);
        //                 else {
        //                     updateUser({ id: json.id, name: json.name, isAdmin: json.is_admin });
        //                     localStorage.setItem('jwt', json.jwt);
        //                 }
        //                 this.setState({ isLoggingIn: false });
        //             })
        //     }
        // });

        history.push(`/dashboard/${form.getFieldValue('username')}`);
    }

    render() {
        const { form, errorMessage } = this.props;
        const { isLoggingIn } = this.state;
        const { getFieldDecorator } = form;
        // const jwt = localStorage.getItem('jwt');

        // if (jwt)
        //     return <Redirect to="/dashboard"/>;
        // else
            return (
                <div className="DashboardLoginPage">
                    <div className="LoginPage-container">
                        <h1>Management System</h1>
                        <div className="LoginPage-form-container">
                            <p className="error-message">{errorMessage}</p>
                            <Form className="LoginPage-form" onSubmit={(e) => this.handleSubmit(e)}>
                                <FormItem>
                                    {getFieldDecorator('username', {
                                        rules: [{ required: true, message: 'Please input your username!' }],
                                    })(
                                        <Input 
                                            placeholder="Username" 
                                        />
                                    )}
                                </FormItem>
                                <FormItem>
                                    {getFieldDecorator('password', {
                                        rules: [{ required: true, message: 'Please input your Password!' }],
                                    })(
                                        <Input 
                                            type="password" 
                                            placeholder="Password"
                                        />
                                    )}
                                </FormItem>
                                <FormItem>
                                    <Button type="primary" htmlType="submit" className="login-form-button" loading={isLoggingIn}>Log in</Button>
                                </FormItem>
                            </Form>
                        </div>
                    </div>
                </div>
            );
    }
}

const WrappedLoginPage = Form.create()(LoginPage);
export default connect(null, actions)(WrappedLoginPage);