import React, { Component } from 'react';
import { Modal } from 'antd';

import './CartoonModal.scss';

export default class CartoonModal extends Component {
    render() {
    	const { message, btnText, ...props } = this.props;

        return (
            <Modal
                className="CartoonModal"
				width="70%"
        		title={null}
        		footer={null}
        		closable={false}
        		maskClosable={false}
        		{...props}
        	>
        		<p>{message}</p>
        		<button onClick={(e) => props.onOk(e)}>{btnText}</button>
        	</Modal>
        );
    }
}