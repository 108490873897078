/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The McResultSubmitAnswers model module.
 * @module model/McResultSubmitAnswers
 * @version 1.0
 */
class McResultSubmitAnswers {
    /**
     * Constructs a new <code>McResultSubmitAnswers</code>.
     * @alias module:model/McResultSubmitAnswers
     */
    constructor() { 
        
        McResultSubmitAnswers.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>McResultSubmitAnswers</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/McResultSubmitAnswers} obj Optional instance to populate.
     * @return {module:model/McResultSubmitAnswers} The populated <code>McResultSubmitAnswers</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new McResultSubmitAnswers();

            if (data.hasOwnProperty('amount')) {
                obj['amount'] = ApiClient.convertToType(data['amount'], 'Number');
            }
            if (data.hasOwnProperty('correct')) {
                obj['correct'] = ApiClient.convertToType(data['correct'], 'Number');
            }
            if (data.hasOwnProperty('incorrect')) {
                obj['incorrect'] = ApiClient.convertToType(data['incorrect'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} amount
 */
McResultSubmitAnswers.prototype['amount'] = undefined;

/**
 * @member {Number} correct
 */
McResultSubmitAnswers.prototype['correct'] = undefined;

/**
 * @member {Number} incorrect
 */
McResultSubmitAnswers.prototype['incorrect'] = undefined;






export default McResultSubmitAnswers;

