/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The OrderQueryResultTx model module.
 * @module model/OrderQueryResultTx
 * @version 1.0
 */
class OrderQueryResultTx {
    /**
     * Constructs a new <code>OrderQueryResultTx</code>.
     * @alias module:model/OrderQueryResultTx
     */
    constructor() { 
        
        OrderQueryResultTx.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>OrderQueryResultTx</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OrderQueryResultTx} obj Optional instance to populate.
     * @return {module:model/OrderQueryResultTx} The populated <code>OrderQueryResultTx</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrderQueryResultTx();

            if (data.hasOwnProperty('amount')) {
                obj['amount'] = ApiClient.convertToType(data['amount'], 'Number');
            }
            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'String');
            }
            if (data.hasOwnProperty('deletedAt')) {
                obj['deletedAt'] = ApiClient.convertToType(data['deletedAt'], 'String');
            }
            if (data.hasOwnProperty('details')) {
                obj['details'] = ApiClient.convertToType(data['details'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('uId')) {
                obj['uId'] = ApiClient.convertToType(data['uId'], 'Number');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} amount
 */
OrderQueryResultTx.prototype['amount'] = undefined;

/**
 * @member {String} createdAt
 */
OrderQueryResultTx.prototype['createdAt'] = undefined;

/**
 * @member {String} deletedAt
 */
OrderQueryResultTx.prototype['deletedAt'] = undefined;

/**
 * @member {String} details
 */
OrderQueryResultTx.prototype['details'] = undefined;

/**
 * @member {Number} id
 */
OrderQueryResultTx.prototype['id'] = undefined;

/**
 * @member {String} type
 */
OrderQueryResultTx.prototype['type'] = undefined;

/**
 * @member {Number} uId
 */
OrderQueryResultTx.prototype['uId'] = undefined;

/**
 * @member {String} updatedAt
 */
OrderQueryResultTx.prototype['updatedAt'] = undefined;






export default OrderQueryResultTx;

