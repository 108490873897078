/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AuthResultSuccess model module.
 * @module model/AuthResultSuccess
 * @version 1.0
 */
class AuthResultSuccess {
    /**
     * Constructs a new <code>AuthResultSuccess</code>.
     * @alias module:model/AuthResultSuccess
     * @param code {Number} 
     * @param expire {String} 
     * @param token {String} 
     */
    constructor(code, expire, token) { 
        
        AuthResultSuccess.initialize(this, code, expire, token);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, code, expire, token) { 
        obj['code'] = code;
        obj['expire'] = expire;
        obj['token'] = token;
    }

    /**
     * Constructs a <code>AuthResultSuccess</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AuthResultSuccess} obj Optional instance to populate.
     * @return {module:model/AuthResultSuccess} The populated <code>AuthResultSuccess</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AuthResultSuccess();

            if (data.hasOwnProperty('code')) {
                obj['code'] = ApiClient.convertToType(data['code'], 'Number');
            }
            if (data.hasOwnProperty('expire')) {
                obj['expire'] = ApiClient.convertToType(data['expire'], 'String');
            }
            if (data.hasOwnProperty('token')) {
                obj['token'] = ApiClient.convertToType(data['token'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} code
 */
AuthResultSuccess.prototype['code'] = undefined;

/**
 * @member {String} expire
 */
AuthResultSuccess.prototype['expire'] = undefined;

/**
 * @member {String} token
 */
AuthResultSuccess.prototype['token'] = undefined;






export default AuthResultSuccess;

