import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import _ from 'lodash';

import * as actions from '../../../../services/dashboard/actions';
import SelectActionPage from '../../components/SelectActionPage/SelectActionPage';
// import ReportPage from './scenes/ReportPage/ReportPage';
import SchoolDataPage from './scenes/SchoolDataPage/SchoolDataPage';
import ClassroomBagDataPage from './scenes/ClassroomBagDataPage/ClassroomBagDataPage';
import HelperReviseTotalPage from './scenes/HelperReviseTotalPage/HelperReviseTotalPage';
import EditHelperSchedulePage from './scenes/EditHelperSchedulePage/EditHelperSchedulePage';
import ApproveStudentDesignPage from './scenes/ApproveStudentDesignPage/ApproveStudentDesignPage';
import './AdminPage.scss';

export class AdminPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            availableActions: [
            	'Generate Report',
            	'View School Data',
            	'View Classroom Bags Data', 
                'Helper Revise Total',
                'Edit Helper Schedule',
                'Approve Student Design'
            ],
            selectedSchool: undefined,
            selectedAction: undefined
        }
    }

    redirectToLoginPage() {
        const { history } = this.props;

        history.push('/dashboard/login');
    }

    handleLogout(e) {
        const { updateDashboardUserInfo } = this.props;

        updateDashboardUserInfo({ isAdmin: false });
        this.redirectToLoginPage();
    }

    handleBack(e) {
        const { selectedAction } = this.state;

        if (_.isUndefined(selectedAction)) {
            this.setState({ selectedSchool: undefined })
        } else {
            this.setState({ selectedAction: undefined })
        }
    }

    renderPages() {
        const { availableActions, selectedAction } = this.state;

        if (_.isUndefined(selectedAction)) {
            return <SelectActionPage
                availableActions={availableActions} 
                onSelectAction={(action) => this.setState({ selectedAction: action })}
            />
        } else {
            switch (selectedAction) {
                case 'Generate Report':
                	return ;
                case 'View School Data':
                	return <SchoolDataPage />;
                case 'View Classroom Bags Data':
                	return <ClassroomBagDataPage />;
                case 'Helper Revise Total':
                	return <HelperReviseTotalPage />;
                case 'Edit Helper Schedule':
                	return <EditHelperSchedulePage />;
                case 'Approve Student Design':
                	return <ApproveStudentDesignPage />;
                default:
                    return;
            }
        }
    }

    render() {
        const { selectedAction } = this.state;

        return (
            <div className="AdminPage">
                <div className="btn-menu">
                    { !_.isUndefined(selectedAction) && <button className="bw-btn back-btn" onClick={(e) => this.handleBack(e)}><Icon type="rollback" />Back</button> }
                    <button className="logout-btn" onClick={(e) => this.handleLogout(e)}>Logout</button>
                </div>
                {this.renderPages()}
            </div>
        );
    }
}

export default connect(null, actions)(AdminPage);