import React, { Component } from 'react';
import { Popconfirm } from 'antd';

import script from './translations';

export class DeletePopconfirmWrapper extends Component {
    render() {
        const { langCode, children, ...rest } = this.props;

        return (
            <Popconfirm 
            	title={script[langCode].confirmMessage}
            	okText={script[langCode].yes}
            	cancelText={script[langCode].no} 
            	{...rest}
            >
            	{children}
            </Popconfirm>
        );
    }
}

DeletePopconfirmWrapper.defaultProps = {
    langCode: 'en'
}

export default DeletePopconfirmWrapper;