import {
    UPDATE_STUDENT_USER_INFO,
} from '../../actionTypes';

const INITIAL_STATE = {
    class: '',
    isHelper: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_STUDENT_USER_INFO:
            return {
                ...state,
                class: action.payload.class,
                isGaming: action.payload.isGaming,
                isHelper: action.payload.isHelper
            };
        default:
            return state;
    }
}