/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import OrgOutputOrg from '../model/OrgOutputOrg';
import UtilHttpErrorBody from '../model/UtilHttpErrorBody';

/**
* Org service.
* @module api/OrgApi
* @version 1.0
*/
export default class OrgApi {

    /**
    * Constructs a new OrgApi. 
    * @alias module:api/OrgApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Get org list
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OrgOutputOrg} and HTTP response
     */
    orgWithHttpInfo(orgId) {
      let postBody = null;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling org");
      }


      let pathParams = {
        'orgId': orgId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = OrgOutputOrg;

      return this.apiClient.callApi(
        '/org/{orgId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get org list
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OrgOutputOrg}
     */
    org(orgId) {
      return this.orgWithHttpInfo(orgId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get specific org by abbv
     * @param {String} orgAbbv org short name
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OrgOutputOrg} and HTTP response
     */
    orgByAbbvWithHttpInfo(orgAbbv) {
      let postBody = null;

      // verify the required parameter 'orgAbbv' is set
      if (orgAbbv === undefined || orgAbbv === null) {
        throw new Error("Missing the required parameter 'orgAbbv' when calling orgByAbbv");
      }


      let pathParams = {
        'orgAbbv': orgAbbv
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = OrgOutputOrg;

      return this.apiClient.callApi(
        '/org-abbv/{orgAbbv}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get specific org by abbv
     * @param {String} orgAbbv org short name
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OrgOutputOrg}
     */
    orgByAbbv(orgAbbv) {
      return this.orgByAbbvWithHttpInfo(orgAbbv)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
