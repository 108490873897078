/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import OrderCreateOrderParam from '../model/OrderCreateOrderParam';
import ShopOutputItems from '../model/ShopOutputItems';
import ShopOutputOrder from '../model/ShopOutputOrder';
import ShopOutputOrders from '../model/ShopOutputOrders';
import ShopOutputUserBalance from '../model/ShopOutputUserBalance';
import UtilHttpErrorBody from '../model/UtilHttpErrorBody';
import UtilHttpOkBody from '../model/UtilHttpOkBody';

/**
* OrgShop service.
* @module api/OrgShopApi
* @version 1.0
*/
export default class OrgShopApi {

    /**
    * Constructs a new OrgShopApi. 
    * @alias module:api/OrgShopApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Create shop order
     * @param {Number} orgId org id
     * @param {module:model/OrderCreateOrderParam} orderCreateOrderParam Create order parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpOkBody} and HTTP response
     */
    orgShopCreateOrderWithHttpInfo(orgId, orderCreateOrderParam) {
      let postBody = orderCreateOrderParam;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling orgShopCreateOrder");
      }

      // verify the required parameter 'orderCreateOrderParam' is set
      if (orderCreateOrderParam === undefined || orderCreateOrderParam === null) {
        throw new Error("Missing the required parameter 'orderCreateOrderParam' when calling orgShopCreateOrder");
      }


      let pathParams = {
        'orgId': orgId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpOkBody;

      return this.apiClient.callApi(
        '/org/{orgId}/shop/order', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create shop order
     * @param {Number} orgId org id
     * @param {module:model/OrderCreateOrderParam} orderCreateOrderParam Create order parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpOkBody}
     */
    orgShopCreateOrder(orgId, orderCreateOrderParam) {
      return this.orgShopCreateOrderWithHttpInfo(orgId, orderCreateOrderParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get specific order
     * @param {Number} orderId order id
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ShopOutputOrder} and HTTP response
     */
    orgShopDeleteOrderWithHttpInfo(orderId, orgId) {
      let postBody = null;

      // verify the required parameter 'orderId' is set
      if (orderId === undefined || orderId === null) {
        throw new Error("Missing the required parameter 'orderId' when calling orgShopDeleteOrder");
      }

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling orgShopDeleteOrder");
      }


      let pathParams = {
        'orderId': orderId,
        'orgId': orgId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ShopOutputOrder;

      return this.apiClient.callApi(
        '/org/{orgId}/shop/order/{orderId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get specific order
     * @param {Number} orderId order id
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ShopOutputOrder}
     */
    orgShopDeleteOrder(orderId, orgId) {
      return this.orgShopDeleteOrderWithHttpInfo(orderId, orgId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get shop items
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ShopOutputItems} and HTTP response
     */
    orgShopItemsWithHttpInfo(orgId) {
      let postBody = null;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling orgShopItems");
      }


      let pathParams = {
        'orgId': orgId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ShopOutputItems;

      return this.apiClient.callApi(
        '/org/{orgId}/shop/item', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get shop items
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ShopOutputItems}
     */
    orgShopItems(orgId) {
      return this.orgShopItemsWithHttpInfo(orgId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get specific order
     * @param {Number} orderId order id
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ShopOutputOrder} and HTTP response
     */
    orgShopOrderWithHttpInfo(orderId, orgId) {
      let postBody = null;

      // verify the required parameter 'orderId' is set
      if (orderId === undefined || orderId === null) {
        throw new Error("Missing the required parameter 'orderId' when calling orgShopOrder");
      }

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling orgShopOrder");
      }


      let pathParams = {
        'orderId': orderId,
        'orgId': orgId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ShopOutputOrder;

      return this.apiClient.callApi(
        '/org/{orgId}/shop/order/{orderId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get specific order
     * @param {Number} orderId order id
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ShopOutputOrder}
     */
    orgShopOrder(orderId, orgId) {
      return this.orgShopOrderWithHttpInfo(orderId, orgId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get all orders of org user
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ShopOutputOrders} and HTTP response
     */
    orgShopOrdersWithHttpInfo(orgId) {
      let postBody = null;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling orgShopOrders");
      }


      let pathParams = {
        'orgId': orgId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ShopOutputOrders;

      return this.apiClient.callApi(
        '/org/{orgId}/shop/order', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all orders of org user
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ShopOutputOrders}
     */
    orgShopOrders(orgId) {
      return this.orgShopOrdersWithHttpInfo(orgId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get user balance
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ShopOutputUserBalance} and HTTP response
     */
    orgShopUserBalanceWithHttpInfo(orgId) {
      let postBody = null;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling orgShopUserBalance");
      }


      let pathParams = {
        'orgId': orgId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ShopOutputUserBalance;

      return this.apiClient.callApi(
        '/org/{orgId}/shop/user-balance', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get user balance
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ShopOutputUserBalance}
     */
    orgShopUserBalance(orgId) {
      return this.orgShopUserBalanceWithHttpInfo(orgId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
