import React, { Component } from 'react';
import { Icon } from 'antd';

import './RankChangeIndicator.scss';

export default class RankChangeIndicator extends Component {
    render() {
        const { rankChange } = this.props;

        if (rankChange > 0) {
        	return (
        		<div className="RankChangeIndicator up">
        			<Icon type="up" />
        			{Math.abs(rankChange)}
        		</div>
        	)
        } else if (rankChange < 0) {
        	return (
        		<div className="RankChangeIndicator down">
        			<Icon type="down" />
        			{Math.abs(rankChange)}
        		</div>
        	)
        } else {
        	return (
        		<div className="RankChangeIndicator">
        			--
        		</div>
        	)
        }
    }
}