/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The WasterecordUpdateRecordParam model module.
 * @module model/WasterecordUpdateRecordParam
 * @version 1.0
 */
class WasterecordUpdateRecordParam {
    /**
     * Constructs a new <code>WasterecordUpdateRecordParam</code>.
     * @alias module:model/WasterecordUpdateRecordParam
     */
    constructor() { 
        
        WasterecordUpdateRecordParam.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>WasterecordUpdateRecordParam</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/WasterecordUpdateRecordParam} obj Optional instance to populate.
     * @return {module:model/WasterecordUpdateRecordParam} The populated <code>WasterecordUpdateRecordParam</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new WasterecordUpdateRecordParam();

            if (data.hasOwnProperty('bagType')) {
                obj['bagType'] = ApiClient.convertToType(data['bagType'], 'String');
            }
            if (data.hasOwnProperty('date')) {
                obj['date'] = ApiClient.convertToType(data['date'], 'String');
            }
            if (data.hasOwnProperty('metal')) {
                obj['metal'] = ApiClient.convertToType(data['metal'], 'Number');
            }
            if (data.hasOwnProperty('paper')) {
                obj['paper'] = ApiClient.convertToType(data['paper'], 'Number');
            }
            if (data.hasOwnProperty('plastic')) {
                obj['plastic'] = ApiClient.convertToType(data['plastic'], 'Number');
            }
            if (data.hasOwnProperty('scheduleId')) {
                obj['scheduleId'] = ApiClient.convertToType(data['scheduleId'], 'Number');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'Number');
            }
            if (data.hasOwnProperty('waste')) {
                obj['waste'] = ApiClient.convertToType(data['waste'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {String} bagType
 */
WasterecordUpdateRecordParam.prototype['bagType'] = undefined;

/**
 * @member {String} date
 */
WasterecordUpdateRecordParam.prototype['date'] = undefined;

/**
 * @member {Number} metal
 */
WasterecordUpdateRecordParam.prototype['metal'] = undefined;

/**
 * @member {Number} paper
 */
WasterecordUpdateRecordParam.prototype['paper'] = undefined;

/**
 * @member {Number} plastic
 */
WasterecordUpdateRecordParam.prototype['plastic'] = undefined;

/**
 * @member {Number} scheduleId
 */
WasterecordUpdateRecordParam.prototype['scheduleId'] = undefined;

/**
 * @member {Number} userId
 */
WasterecordUpdateRecordParam.prototype['userId'] = undefined;

/**
 * @member {Number} waste
 */
WasterecordUpdateRecordParam.prototype['waste'] = undefined;






export default WasterecordUpdateRecordParam;

