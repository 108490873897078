import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import {OrgApi} from "../../apiClient";
import {connect} from "react-redux";

import * as actions from "../../services/translation/actions";

export class SchoolRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSchoolIdValid: false,
            isAuthenticating: true
        }
    }

    authenticate() {
        const { computedMatch } = this.props;
        const schoolId = computedMatch.params.schoolId;

        new OrgApi().orgByAbbv(schoolId).then((data)=>{
            window.schoolId = data.org.id;
            this.setState({ isSchoolIdValid: true, isAuthenticating: false });
            this.props.updateTranslationSetting(data.org.defaultLangCode);
        }).catch(()=>{
            this.setState({ isAuthenticating: false });
        })
    }

    componentWillMount() {
        this.authenticate();
    }

    componentWillUpdate(nextProps, nextState) {
        const { computedMatch } = this.props;
        const schoolId = computedMatch.params.schoolId;

        if (schoolId !== nextProps.computedMatch.params.schoolId)
            this.authenticate();
    }

    render() {
        const { component, ...rest } = this.props;
        const { isSchoolIdValid, isAuthenticating } = this.state

        if (isAuthenticating)
            return (<LoadingScreen loadingText="Authenticating..."/>);
        else
            return (
                <Route 
                    {...rest }
                    render={ props => isSchoolIdValid ? React.createElement(component, props) : <Redirect to="/"/> }
                />
            );
    }
}

export default connect(() => ({}), actions)(SchoolRoute);