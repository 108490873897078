import React, { Component } from 'react';
import _ from 'lodash';

import './SelectSchoolPage.scss';

export class SelectSchoolPage extends Component {
    render() {
        const { availableSchools, onSelectSchool } = this.props;
        const schoolOptions = _.map(availableSchools, (school) => (
        	<div key={school.id} className="school-option bw-btn" onClick={(e) => {onSelectSchool(school.id)}}>
        		{school.name}
        	</div>
        ));

        return (
            <div className="SelectSchoolPage">
            	<p className="instruction">Please select a school</p>
            	<div className="school-options-gp">
            		{schoolOptions}
            	</div>
			</div>
        );
    }
}

export default SelectSchoolPage;