import React, { Component } from 'react';

import blankPageLogo from './logo.svg';
import './BlankPage.scss';

export class BlankPage extends Component {
	render() {
		return (
			<div className="BlankPage">
				<img src={blankPageLogo} alt="Blank Page"/>
			</div>
		);
	}
}

export default BlankPage;