import React, { Component } from 'react';
import { Icon, Modal, Table } from 'antd';
import moment from 'moment';
import cx from 'classnames';
import _ from 'lodash';

import DeletePopconfirmWrapper from '../../../../../../components/DeletePopconfirmWrapper/DeletePopconfirmWrapper';
import FilterGroup from '../../../../../../components/FilterGroup/FilterGroup';
import './ApproveStudentDesignPage.scss';

export class ApproveStudentDesignPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            availableClasses: [],
            dataset: _.map(_.range(0, 100), (i) => ({
                id: i,
                name: `Student ${i}`,
                date: moment().format(),
                class: '1A',
                is_revised: [true, false][_.random(0,1)]
            })),
            filters: {},
            isPictureModalVisible: false,
            pictureUrl: undefined
        }
    }

    render() {
        const { availableClasses, dataset, isPictureModalVisible, pictureUrl } = this.state;

        const filterList = [{
            type: 'dateRange',
            name: 'date',
            placeholder: ['From', 'To']
        }, {
            type: 'select',
            name: 'class',
            placeholder: 'Class',
            options: _.map(availableClasses, (classObj) => ({ label: classObj, value: classObj }))
        }];
        const columns = [{
            title: 'Date',
            dataIndex: 'date',
            width: 200,
            align: 'center',
            render: (date, record) => moment(date).format('YYYY-MM-DD')
        }, {
            title: 'Class',
            dataIndex: 'class',
            width: 100,
            align: 'center'
        }, {
            title: 'Name',
            dataIndex: 'name',
            align: 'left'
        }, {
            title: 'Design',
            dataIndex: 'design',
            width: 100,
            align: 'center',
            render: (value, record) => <Icon className="icon-btn" type="picture" onClick={(e) => this.setState({ isPictureModalVisible: true, pictureUrl: value })}/>
        }, {
            title: 'Revised?',
            dataIndex: 'is_revised',
            width: 100,
            align: 'center',
            render: (value, record) => <Icon className={cx({ 'icon-btn': true, 'check-btn': true, 'checked': value })} type="check"/>
        }, {
            title: '',
            dataIndex: 'delete',
            key: 'delete',
            width: 50,
            align: 'center',
            render: (text, record) => 
                <DeletePopconfirmWrapper onConfirm={() => console.log(`Delete ${record.id}`)}>
                    <Icon className="icon-btn remove-btn" type="close" />
                </DeletePopconfirmWrapper>
        }];

        return (
            <div className="ApproveStudentDesignPage">
				<div className="table-section">
                    <div className="title-section">
                        <h3>Approve Student Design</h3>
                        <FilterGroup
                            filterList={filterList}
                            onChange={(filters) => this.setState({ filters })}
                        />
                    </div>
					<Table
                        rowKey="id"
						columns={columns}
						dataSource={dataset}
					/>
				</div>
                <Modal
                    visible={isPictureModalVisible}
                    header={null}
                    footer={null}
                    onCancel={(e) => this.setState({ isPictureModalVisible: false, pictureUrl: undefined })}
                >
                    <img src={pictureUrl} alt=""/>
                </Modal>
			</div>
        );
    }
}

export default ApproveStudentDesignPage;