import React, { Component } from 'react';
import { Col, DatePicker, Form, Icon, InputNumber, Popconfirm, Row, Select, Table } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import FilterGroup from '../../../../../../components/FilterGroup/FilterGroup';
import './WasteUsagePage.scss';

const FormItem = Form.Item;
const Option = Select.Option;

export class WasteUsagePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            availableClasses: ['Other', '1A', '1B', '1C'],
            dataset: _.map(_.range(0, 100), (i) => ({
                id: i,
                date: moment().format(),
                class: '1A',
                paper: parseFloat((Math.random() * 10).toFixed(2)),
                plastic: parseFloat((Math.random() * 10).toFixed(2)),
                metal: parseFloat((Math.random() * 10).toFixed(2)),
                waste: parseFloat((Math.random() * 10).toFixed(2)),
            })),
        }
    }

    handleSubmit(e) {
        e.preventDefault();
    }

    render() {
        const { form } = this.props;
        const { availableClasses, dataset } = this.state;
        const { getFieldDecorator } = form;

        const classOptions = _.map(availableClasses, (classObj) => <Option key={classObj} value={classObj}>{classObj}</Option>);
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 2 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        const subFormItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 12 },
            },
        };

        const filterList = [{
            type: 'dateRange',
            name: 'date',
            placeholder: ['From', 'To']
        }, {
            type: 'select',
            name: 'class',
            placeholder: 'Class',
            options: _.map(availableClasses, (classObj) => ({ label: classObj, value: classObj }))
        }];
        const columns = [{
            title: '',
            dataIndex: 'edit',
            key: 'edit',
            width: 50,
            align: 'center',
            render: (text, record) => (<Icon className="icon-btn" type="edit" onClick={() => this.setState({ isBagFormModalVisible: true, recordId: record.id })} />)
        }, {
            title: 'Date',
            dataIndex: 'date',
            align: 'center',
            render: (date, record) => moment(date).format('YYYY-MM-DD')
        }, {
            title: 'Class',
            dataIndex: 'class',
            align: 'center'
        }, {
            title: 'Paper',
            dataIndex: 'paper',
            align: 'center'
        }, {
            title: 'Plastic',
            dataIndex: 'plastic',
            align: 'center',
        }, {
            title: 'Metal',
            dataIndex: 'metal',
            align: 'center',
        }, {
            title: 'Waste',
            dataIndex: 'waste',
            align: 'center',
        }, {
            title: '',
            dataIndex: 'delete',
            key: 'delete',
            width: 50,
            align: 'center',
            render: (text, record) => (
                <Popconfirm title="Are you sure to delete this record?" okText="Yes" cancelText="No" onConfirm={(e) => console.log(record.id)}>
                    <Icon className="icon-btn remove-btn" type="close"  />
                </Popconfirm>)
        }];
        const wasteDistribution = _.map(['paper', 'plastic', 'metal', 'waste'], waste => ({
        	waste,
        	total: parseFloat(_.sumBy(dataset, waste).toFixed(2))
        }));
        const wasteTableFooter = () => <div className="table-footer">
        	Total Waste - {_.map(wasteDistribution, waste => `${waste.waste}: ${waste.total}kg`).join(', ')}
        </div>;

        return (
            <div className="WasteUsagePage">
				<div className="input-section">
					<h3>Input Waste Usage</h3>
					<Form onSubmit={(e) => this.handleSubmit(e)} hideRequiredMark={true}>
						<Row>
							<Col span={24}>
								<FormItem 
									{...formItemLayout}
									label="Date"
								>
		                            {getFieldDecorator('date', {
		                                rules: [{ required: true }],
		                            })(
		                                <DatePicker 
		                                    placeholder="Date" 
		                                />
		                            )}
								</FormItem>
							</Col>
						</Row>
						<Row>
							<Col span={24}>
								<FormItem 
									{...formItemLayout}
									label="Class"
								>
		                            {getFieldDecorator('class', {
		                                rules: [{ required: form.getFieldValue('bagType') === 'Big Waster Bag' }],
		                            })(
		                                <Select 
		                                    placeholder="Class" 
		                                >
		                                	{classOptions}
		                                </Select>
		                            )}
								</FormItem>
							</Col>
						</Row>
						<Row>
							<Col span={6}>
								<FormItem 
									{...subFormItemLayout}
									label="Paper"
								>
		                            {getFieldDecorator('paper', {
		                                rules: [{ required: true }],
		                            })(
		                                <InputNumber 
		                                	min={0}
		                                />
		                            )}
		                            <span className="unit">kg</span>
								</FormItem>
							</Col>
							<Col span={6}>
								<FormItem 
									{...subFormItemLayout}
									label="Plastic"
								>
		                            {getFieldDecorator('plastic', {
		                                rules: [{ required: true }],
		                            })(
		                                <InputNumber 
		                                	min={0}
		                                />
		                            )}
		                            <span className="unit">kg</span>
								</FormItem>
							</Col>
							<Col span={6}>
								<FormItem 
									{...subFormItemLayout}
									label="Metal"
								>
		                            {getFieldDecorator('metal', {
		                                rules: [{ required: true }],
		                            })(
		                                <InputNumber 
		                                	min={0}
		                                />
		                            )}
		                            <span className="unit">kg</span>
								</FormItem>
							</Col>
							<Col span={6}>
								<FormItem 
									{...subFormItemLayout}
									label="Waste"
								>
		                            {getFieldDecorator('waste', {
		                                rules: [{ required: true }],
		                            })(
		                                <InputNumber 
		                                	min={0}
		                                />
		                            )}
		                            <span className="unit">kg</span>
								</FormItem>
							</Col>
						</Row>
						<FormItem className="form-submit-btn">
							<button className="bw-btn">Submit</button>
						</FormItem>
					</Form>
				</div>
				<div className="table-section">
					<div className="title-section">
						<h3>Review Waste Usage</h3>
                        <FilterGroup
                            filterList={filterList}
                            onChange={(filters) => this.setState({ filters })}
                        />
					</div>
					<Table
        				rowKey="id"
						columns={columns}
						dataSource={dataset}
						footer={wasteTableFooter}
					/>
				</div>
			</div>
        );
    }
}

const WrappedWasteUsagePage = Form.create()(WasteUsagePage);
export default WrappedWasteUsagePage;