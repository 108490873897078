module.exports = {
	'en': {
		'confirmMessage': 'Are you sure to delete this record?' ,
		'yes': 'Yes',
		'no': 'No'
	},
	'tc': {
		'confirmMessage': '你確定要刪除這則紀錄？',
		'yes': '是',
		'no': '否'
	}
}