import React, { Component } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

import FilterGroup from '../../../../../../components/FilterGroup/FilterGroup';
import './HelperReviseTotalPage.scss';

export class HelperReviseTotalPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            availableSchools: ['School1', 'School2'],
            availableYears: _.range(1, 6),
            availableBags: ['1L', '2L', '3L'],
            dataset: _.map(_.range(0, 3), (i) => ({
                id: i,
                school: 'School1',
                class: '1A',
                bag_type: `${i+1}L`,
                total: _.map(_.range(0, 12), month => _.random(90, 100)),
                revised: _.map(_.range(0, 12), month => _.random(50, 90))
            })),
            filters: {}
        }
    }

    render() {
        const { availableSchools, availableYears, availableBags, dataset } = this.state;

        const filterList = [{
            type: 'multiSelect',
            name: 'school',
            placeholder: '(All Schools)',
            options: _.map(availableSchools, (school) => ({ label: school, value: school }))
        }, {
            type: 'multiSelect',
            name: 'year',
            placeholder: '(All Years)',
            options: _.map(availableYears, (year) => ({ label: year, value: year }))
        }, {
            type: 'multiSelect',
            name: 'bag_type',
            placeholder: '(All Bag Types)',
            options: _.map(availableBags, (bagObj) => ({ label: bagObj, value: bagObj }))
        }];
        const columns = [{
            title: 'School',
            dataIndex: 'school',
            align: 'center',
            sorter: (a, b) => a.school.localeCompare(b.school),
        }, {
            title: 'Class',
            dataIndex: 'class',
            align: 'center',
            sorter: (a, b) => a.class.localeCompare(b.class),
        }, {
            title: 'Bag Type',
            dataIndex: 'bag_type',
            align: 'center',
            sorter: (a, b) => a.bag_type.localeCompare(b.bag_type),
        }, {
            title: 'Total(Monthly)',
            dataIndex: 'total',
            align: 'center',
            render: (array, record) => _.map(array, (item, i) => <p key={i}>{`${moment().month(i).format('MMM')}: ${numeral(item).format('0,0.[00]')}`}</p>)
        }, {
            title: 'Revised(Monthly)',
            dataIndex: 'revised',
            align: 'center',
            render: (array, record) => _.map(array, (item, i) => <p key={i}>{`${moment().month(i).format('MMM')}: ${numeral(item).format('0,0.[00]')}`}</p>)
        }];

        return (
            <div className="HelperReviseTotalPage">
                <div className="table-section">
                    <h3>Helper Revise Total</h3>
                    <FilterGroup
                        filterList={filterList}
                        onChange={(filters) => this.setState({ filters })}
                    />
                    <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={dataset}
                    />
                </div>
            </div>
        );
    }
}

export default HelperReviseTotalPage;