import {
    UPDATE_DASHBOARD_USER_INFO,
} from '../../actionTypes';

const INITIAL_STATE = {
    isAdmin: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_DASHBOARD_USER_INFO:
            return {
                ...state,
                isHelper: action.payload.isAdmin
            };
        default:
            return state;
    }
}