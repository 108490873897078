import React, { Component } from 'react';
import { DatePicker, Form, Icon, Input, Popconfirm, Select, Table } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import cx from 'classnames';
import _ from 'lodash';

import FilterGroup from '../../../../../../components/FilterGroup/FilterGroup';
import BagFormModal from '../../../../../../components/BagFormModal/BagFormModal';
import './BagUsagePage.scss';

const FormItem = Form.Item;
const Option = Select.Option;

export class BagUsagePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            availableClasses: ['1A', '1B', '1C'],
            availableBags: ['Non-designated Bags', '1L', '2L', '3L'],
            dataset: _.map(_.range(0, 100), (i) => ({
                id: i,
                date: moment().format(),
                class: '1A',
                bag_type: `${Math.round(Math.random() * 3 + 1)}L`,
                quantity: Math.ceil(Math.random() * 10),
                is_revised: [true, false][_.random(0,1)]
            })),
            isBagFormModalVisible: false,
            recordId: undefined,
            filters: {}
        }
    }

    handleSubmit(e) {
        const { form } = this.props
        e.preventDefault();

        form.validateFields((err, values) => {
            if (err)
                console.log(err);
            else {

            }
        });
    }

    render() {
        const { form } = this.props;
        const { availableClasses, availableBags, dataset, isBagFormModalVisible, recordId } = this.state;
        const { getFieldDecorator } = form;

        const filterList = [{
            type: 'dateRange',
            name: 'date',
            placeholder: ['From', 'To']
        }, {
            type: 'select',
            name: 'class',
            placeholder: 'Class',
            options: _.map(availableClasses, (classObj) => ({ label: classObj, value: classObj }))
        }, {
            type: 'select',
            name: 'bag_type',
            placeholder: 'Bag Type',
            options: _.map(availableBags, (bagObj) => ({ label: bagObj, value: bagObj }))
        }];
        const columns = [{
            title: '',
            dataIndex: 'edit',
            key: 'edit',
            width: 50,
            align: 'center',
            render: (text, record) =>  !record.is_revised && (<Icon className="icon-btn" type="edit" onClick={() => this.setState({ isBagFormModalVisible: true, recordId: record.id })} />)
        }, {
            title: 'Date',
            dataIndex: 'date',
            align: 'center',
            render: (date, record) => moment(date).format('YYYY-MM-DD')
        }, {
            title: 'Class',
            dataIndex: 'class',
            align: 'center'
        }, {
            title: 'Bag Type',
            dataIndex: 'bag_type',
            align: 'center'
        }, {
            title: 'Quantity',
            dataIndex: 'quantity',
            align: 'center',
        }, {
            title: 'Total Cost',
            dataIndex: 'total_cost',
            align: 'center',
            render: (value, record) => numeral(record.quantity * 1.2).format('0,0.[00]')
        }, {
            title: 'Revised?',
            dataIndex: 'is_revised',
            width: 100,
            align: 'center',
            render: (value, record) => <Icon className={cx({ 'icon-btn': true, 'check-btn': true, 'checked': value })} type="check"/>
        }, {
            title: '',
            dataIndex: 'delete',
            key: 'delete',
            width: 50,
            align: 'center',
            render: (text, record) => (
                <Popconfirm title="Are you sure to delete this record?" okText="Yes" cancelText="No" onConfirm={(e) => console.log(record.id)}>
                    <Icon className="icon-btn remove-btn" type="close"  />
                </Popconfirm>)
        }];

        const classOptions = _.map(availableClasses, (classObj) => <Option key={classObj} value={classObj}>{classObj}</Option>);
        const bagOptions = _.map(availableBags, (bag) => <Option key={bag} value={bag}>{bag}</Option>);

		const bagDistribution = _.sortBy(_.map(_.groupBy(dataset, 'bag_type'), (rows, key) => ({ key, count: _.sumBy(rows, 'quantity') })), 'key');
        const bagTableFooter = () => <div className="table-footer">
        	Total Bags - {_.map(bagDistribution, (d) => `${d.key}: ${d.count}`).join(', ')}
        </div>;

        return (
            <div className="BagUsagePage">
				<div className="input-section">
					<h3>Input Bag Usage</h3>
					<Form layout="inline" onSubmit={(e) => this.handleSubmit(e)}>
						<FormItem>
                            {getFieldDecorator('date', {
                                rules: [{ required: true }],
                            })(
                                <DatePicker 
                                    placeholder="Date" 
                                />
                            )}
						</FormItem>
						<FormItem>
                            {getFieldDecorator('class', {
                                rules: [{ required: true }],
                            })(
                                <Select 
                                    placeholder="Class" 
                                >
                                	{classOptions}
                                </Select>
                            )}
						</FormItem>
						<FormItem>
                            {getFieldDecorator('bagType', {
                                rules: [{ required: true }],
                            })(
                                <Select 
                                    placeholder="Bag Type" 
                                >
                                	{bagOptions}
                                </Select>
                            )}
						</FormItem>
						<FormItem>
                            {getFieldDecorator('amount', {
                                rules: [{ required: true }],
                            })(
                                <Input 
                                    placeholder="Amount" 
                                />
                            )}
						</FormItem>
						<FormItem>
							<button className="bw-btn">Submit</button>
						</FormItem>
					</Form>
				</div>
				<div className="table-section">
                    <div className="title-section">
                        <h3>Review Bag Usage</h3>
                        <FilterGroup
                            filterList={filterList}
                            onChange={(filters) => this.setState({ filters })}
                        />
                    </div>
					<Table
                        rowKey="id"
						columns={columns}
						dataSource={dataset}
						footer={bagTableFooter}
					/>
				</div>
				<BagFormModal
					visible={isBagFormModalVisible}
                    recordId={recordId}
					onCancel={(e) => this.setState({ isBagFormModalVisible: false })}
					afterSubmit={() => {console.log('fetch')}}
				/>
			</div>
        );
    }
}

const WrappedBagUsagePage = Form.create()(BagUsagePage);
export default WrappedBagUsagePage;