import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { LocaleProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import global from './global';
import store from './store'
import AppRouter from './router';
import './index.scss';
import ApiClient from "./apiClient/ApiClient";

// Set basePath
ApiClient.instance.basePath = process.env.REACT_APP_API_URL.replace(/\/+$/, '');

// Prevent save token when user click back button
window.onpopstate = function(e)
{
        window.location.reload()
};

ReactDOM.render(
    <Provider store={store}>
	    <LocaleProvider locale={enUS}>
		    <AppRouter/>
		</LocaleProvider>
	</Provider>, document.getElementById('root')
);