import React, { Component } from 'react';
import cx from 'classnames';

import './SelectorBox.scss';

export default class SelectorBox extends Component {
    render() {
        const { text, isSelected, ...props } = this.props;

        return (
            <div 
	            className={cx({
	            	'SelectorBox': true,
	            	'selected': isSelected
	            })}
	            {...props}
	        >
				{text}
			</div>
        );
    }
}