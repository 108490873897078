import React, { Component } from 'react';
import { Icon, Spin } from 'antd';

import './LoadingScreen.scss';

export default class LoadingScreen extends Component {
    render() {
        const { loadingText } = this.props;
        const antIcon = <Icon type="loading" style={{ fontSize: 36 }} spin />;

        return (
            <div className="LoadingScreen">
            	<Spin size="large" tip={loadingText} indicator={antIcon}/>
            </div>
        );
    }
}