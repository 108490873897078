import React, { Component } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import './HelperSchedulePage.scss';

export class HelperSchedulePage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataset: _.map(_.range(0, 2), (i) => ({
                id: i,
                date: moment().format(),
                helper: 'Helper 1',
                school: ['元朗朗屏邨惠州學校', '香海正覺蓮社佛教陳式宏學校', '大角嘴天主教小學(海帆道)'][i],
            })),
        }
    }

    render() {
        const { dataset } = this.state;

        const columns = [{
        	width: 150,
            title: 'Date',
            dataIndex: 'date',
            align: 'center',
            render: (date, record) => moment(date).format('YYYY-MM-DD')
        }, {
        	width: 200,
            title: 'Helper',
            dataIndex: 'helper',
            align: 'center',
        }, {
            title: 'School',
            dataIndex: 'school',
            align: 'center'
        }];

        return (
            <div className="HelperSchedulePage">
				<h3>Helper's Schedule</h3>
				<div className="contact-section">
					<p>Coresponding Person: XXXX XXXX XXXX (98765432)</p>
					<p>Coordinator: XXXX XXXX XXXX (98765432)</p>
				</div>
				<div className="table-section">
                    <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={dataset}
                    />
				</div>
			</div>
        );
    }
}

export default HelperSchedulePage;