export default {
	'en': {
		'login': 'Login',
		'logout': 'Log-out',
		'class': 'Class'
	},
	'tc': {
		'login': '登入',
		'logout': '登出',
		'class': '班別'
	}
}