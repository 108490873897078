import React, { Component } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import _ from 'lodash';

import FilterGroup from '../../../../../../components/FilterGroup/FilterGroup';
import './ClassroomBagDataPage.scss';

export class ClassroomBagDataPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            availableSchools: ['School1', 'School2'],
            availableClasses: ['1A', '1B', '1C'],
            availableYears: _.range(1, 6),
            availableMonths: _.range(0, 11),
            availableBags: ['1L', '2L', '3L'],
            dataset: _.map(_.range(0, 100), (i) => ({
                id: i,
                school: Math.random() > 0.5 ? 'School1' : 'School2',
                year: '1',
                class: '1A',
                month: _.random(0, 11),
                '1LTotal': _.random(0, 100),
                '2LTotal': _.random(0, 100),
                '3LTotal': _.random(0, 100),
            })),
            filters: {}
        }
    }

    render() {
        const { availableSchools, availableClasses, availableYears, availableMonths, availableBags, dataset, filters } = this.state;

        const filterList = [{
            type: 'multiSelect',
            name: 'school',
            placeholder: '(All Schools)',
            options: _.map(availableSchools, (school) => ({ label: school, value: school }))
        }, {
            type: 'multiSelect',
            name: 'class',
            placeholder: '(All Classes)',
            options: _.map(availableClasses, (classObj) => ({ label: classObj, value: classObj }))
        }, {
            type: 'multiSelect',
            name: 'year',
            placeholder: '(All Years)',
            options: _.map(availableYears, (year) => ({ label: year, value: year }))
        }, {
            type: 'multiSelect',
            name: 'month',
            placeholder: '(All Months)',
            options: _.map(availableMonths, (month) => ({ label: moment().month(month).format('MMM'), value: month }))
        }, {
            type: 'multiSelect',
            name: 'bag_type',
            placeholder: '(All Bag Types)',
            options: _.map(availableBags, (bagObj) => ({ label: bagObj, value: bagObj }))
        }];
        const columns = [{
            title: 'School',
            dataIndex: 'school',
            align: 'center',
            sorter: (a, b) => a.school.localeCompare(b.school),
        }, {
            title: 'Year',
            dataIndex: 'year',
            align: 'center',
            sorter: (a, b) => a.year - b.year,
        }, {
            title: 'Class',
            dataIndex: 'class',
            align: 'center',
            sorter: (a, b) => a.class.localeCompare(b.class),
        }, {
            title: 'Month',
            dataIndex: 'month',
            align: 'center',
            sorter: (a, b) => a.month - b.month,
            render: (value, record) => moment().month(value).format('MMM')
        }];
        const bagDistribution = _.map(filters.bag_type || availableBags, (bag) => ({
            bag,
            total: _.sumBy(dataset, `${bag}Total`)
        }));
        const bagTableFooter = () => 
            <div className="table-footer">
                Total Bags: {_.sumBy(bagDistribution, 'total')} - {_.map(bagDistribution, d => `${d.bag}: ${d.total}`).join(', ')}
            </div>;

        return (
            <div className="ClassroomBagDataPage">
                <div className="table-section">
                    <h3>View Classroom Bag Data</h3>
                    <FilterGroup
                        filterList={filterList}
                        onChange={(filters) => this.setState({ filters })}
                    />
                    <Table
                        rowKey="id"
                        columns={_.concat(columns, _.map(filters.bag_type || availableBags, bag => ({
                            title: `${bag} Total`,
                            dataIndex: `${bag}Total`,
                            align: 'center',
                            sorter: (a, b) => a[`${bag}Total`] - b[`${bag}Total`],
                            render: (value, record) => numeral(value).format('0.[00]')
                        })))}
                        dataSource={dataset}
                        footer={bagTableFooter}
                    />
                </div>
            </div>
        );
    }
}

export default ClassroomBagDataPage;