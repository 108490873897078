/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CompetitionOutputCompetition from '../model/CompetitionOutputCompetition';
import SubmissionCreateSubmissionParam from '../model/SubmissionCreateSubmissionParam';
import SubmissionOutputSubmission from '../model/SubmissionOutputSubmission';
import SubmissionOutputSubmissions from '../model/SubmissionOutputSubmissions';
import SubmissionOutputUploadSubmissionFile from '../model/SubmissionOutputUploadSubmissionFile';
import UtilHttpErrorBody from '../model/UtilHttpErrorBody';
import UtilHttpOkBody from '../model/UtilHttpOkBody';

/**
* OrgCompetition service.
* @module api/OrgCompetitionApi
* @version 1.0
*/
export default class OrgCompetitionApi {

    /**
    * Constructs a new OrgCompetitionApi. 
    * @alias module:api/OrgCompetitionApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Get all org competitions
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompetitionOutputCompetition} and HTTP response
     */
    competitionsWithHttpInfo(orgId) {
      let postBody = null;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling competitions");
      }


      let pathParams = {
        'orgId': orgId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CompetitionOutputCompetition;

      return this.apiClient.callApi(
        '/org/{orgId}/competition', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get all org competitions
     * @param {Number} orgId org id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompetitionOutputCompetition}
     */
    competitions(orgId) {
      return this.competitionsWithHttpInfo(orgId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get specific org competition
     * @param {Number} orgId org id
     * @param {Number} competitionId competition id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CompetitionOutputCompetition} and HTTP response
     */
    orgCompetitionWithHttpInfo(orgId, competitionId) {
      let postBody = null;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling orgCompetition");
      }

      // verify the required parameter 'competitionId' is set
      if (competitionId === undefined || competitionId === null) {
        throw new Error("Missing the required parameter 'competitionId' when calling orgCompetition");
      }


      let pathParams = {
        'orgId': orgId,
        'competitionId': competitionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CompetitionOutputCompetition;

      return this.apiClient.callApi(
        '/org/{orgId}/competition/{competitionId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get specific org competition
     * @param {Number} orgId org id
     * @param {Number} competitionId competition id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CompetitionOutputCompetition}
     */
    orgCompetition(orgId, competitionId) {
      return this.orgCompetitionWithHttpInfo(orgId, competitionId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Create submission
     * @param {Number} orgId org id
     * @param {Number} competitionId competition id
     * @param {module:model/SubmissionCreateSubmissionParam} submissionCreateSubmissionParam Create submission parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UtilHttpOkBody} and HTTP response
     */
    orgCompetitionCreateSubmissionWithHttpInfo(orgId, competitionId, submissionCreateSubmissionParam) {
      let postBody = submissionCreateSubmissionParam;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling orgCompetitionCreateSubmission");
      }

      // verify the required parameter 'competitionId' is set
      if (competitionId === undefined || competitionId === null) {
        throw new Error("Missing the required parameter 'competitionId' when calling orgCompetitionCreateSubmission");
      }

      // verify the required parameter 'submissionCreateSubmissionParam' is set
      if (submissionCreateSubmissionParam === undefined || submissionCreateSubmissionParam === null) {
        throw new Error("Missing the required parameter 'submissionCreateSubmissionParam' when calling orgCompetitionCreateSubmission");
      }


      let pathParams = {
        'orgId': orgId,
        'competitionId': competitionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UtilHttpOkBody;

      return this.apiClient.callApi(
        '/org/{orgId}/competition/{competitionId}/submission', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Create submission
     * @param {Number} orgId org id
     * @param {Number} competitionId competition id
     * @param {module:model/SubmissionCreateSubmissionParam} submissionCreateSubmissionParam Create submission parameters
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UtilHttpOkBody}
     */
    orgCompetitionCreateSubmission(orgId, competitionId, submissionCreateSubmissionParam) {
      return this.orgCompetitionCreateSubmissionWithHttpInfo(orgId, competitionId, submissionCreateSubmissionParam)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get specific approved submission
     * @param {Number} orgId org id
     * @param {Number} competitionId competition id
     * @param {Number} submissionId submission id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubmissionOutputSubmission} and HTTP response
     */
    orgCompetitionSubmissionWithHttpInfo(orgId, competitionId, submissionId) {
      let postBody = null;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling orgCompetitionSubmission");
      }

      // verify the required parameter 'competitionId' is set
      if (competitionId === undefined || competitionId === null) {
        throw new Error("Missing the required parameter 'competitionId' when calling orgCompetitionSubmission");
      }

      // verify the required parameter 'submissionId' is set
      if (submissionId === undefined || submissionId === null) {
        throw new Error("Missing the required parameter 'submissionId' when calling orgCompetitionSubmission");
      }


      let pathParams = {
        'orgId': orgId,
        'competitionId': competitionId,
        'submissionId': submissionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = SubmissionOutputSubmission;

      return this.apiClient.callApi(
        '/org/{orgId}/competition/{competitionId}/submission/{submissionId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get specific approved submission
     * @param {Number} orgId org id
     * @param {Number} competitionId competition id
     * @param {Number} submissionId submission id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubmissionOutputSubmission}
     */
    orgCompetitionSubmission(orgId, competitionId, submissionId) {
      return this.orgCompetitionSubmissionWithHttpInfo(orgId, competitionId, submissionId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get approved submissions
     * @param {Number} orgId org id
     * @param {Number} competitionId competition id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubmissionOutputSubmissions} and HTTP response
     */
    orgCompetitionSubmissionsWithHttpInfo(orgId, competitionId) {
      let postBody = null;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling orgCompetitionSubmissions");
      }

      // verify the required parameter 'competitionId' is set
      if (competitionId === undefined || competitionId === null) {
        throw new Error("Missing the required parameter 'competitionId' when calling orgCompetitionSubmissions");
      }


      let pathParams = {
        'orgId': orgId,
        'competitionId': competitionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = SubmissionOutputSubmissions;

      return this.apiClient.callApi(
        '/org/{orgId}/competition/{competitionId}/submission', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Get approved submissions
     * @param {Number} orgId org id
     * @param {Number} competitionId competition id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubmissionOutputSubmissions}
     */
    orgCompetitionSubmissions(orgId, competitionId) {
      return this.orgCompetitionSubmissionsWithHttpInfo(orgId, competitionId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Upload competition&#39;s submission file
     * @param {Number} orgId org id
     * @param {Number} competitionId competition id
     * @param {File} file image file
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SubmissionOutputUploadSubmissionFile} and HTTP response
     */
    orgCompetitionUploadCompetitionSubmissionWithHttpInfo(orgId, competitionId, file) {
      let postBody = null;

      // verify the required parameter 'orgId' is set
      if (orgId === undefined || orgId === null) {
        throw new Error("Missing the required parameter 'orgId' when calling orgCompetitionUploadCompetitionSubmission");
      }

      // verify the required parameter 'competitionId' is set
      if (competitionId === undefined || competitionId === null) {
        throw new Error("Missing the required parameter 'competitionId' when calling orgCompetitionUploadCompetitionSubmission");
      }

      // verify the required parameter 'file' is set
      if (file === undefined || file === null) {
        throw new Error("Missing the required parameter 'file' when calling orgCompetitionUploadCompetitionSubmission");
      }


      let pathParams = {
        'orgId': orgId,
        'competitionId': competitionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': file
      };

      let authNames = ['ApiKeyAuth'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = SubmissionOutputUploadSubmissionFile;

      return this.apiClient.callApi(
        '/org/{orgId}/competition/{competitionId}/submission/file-upload', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Upload competition&#39;s submission file
     * @param {Number} orgId org id
     * @param {Number} competitionId competition id
     * @param {File} file image file
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SubmissionOutputUploadSubmissionFile}
     */
    orgCompetitionUploadCompetitionSubmission(orgId, competitionId, file) {
      return this.orgCompetitionUploadCompetitionSubmissionWithHttpInfo(orgId, competitionId, file)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
