export default {
	'en': {
		'gameInstruction': 'Select Your Class to Start Game',
		'helperInstruction': 'Select Your Class and Enter the Password',
		'primary': 'Primary',
		'classLabel': 'Class',
		'startGame': 'Start Game',
		'enterPassword': 'Enter Password',
		'password': 'Password',
		'login': 'Log In',
		'ok': 'OK',
		'modalClassErrMessage': 'Please select your class.',
		'modalPasswordErrMessage': 'Please input the password.',
        'modalPasswordIncorrectErrMessage': 'Please input the correct password.'
	},
	'tc': {
		'gameInstruction': '選擇你的班別以開始遊戲',
		'helperInstruction': '選擇你的班別並輸入密碼',
		'primary': '級',
		'classLabel': '班',
		'startGame': '開始遊戲',
		'password': '密碼',
		'enterPassword': '輸入密碼',
		'login': '登入',
		'ok': '明白',
		'modalClassErrMessage': '請先選擇你的班別。',
		'modalPasswordErrMessage': '請輸入密碼。',
        'modalPasswordIncorrectErrMessage': '請輸入正確密碼。',

	}
}