import { combineReducers } from 'redux';

import TranslationReducer from './services/translation/reducer';
import StudentUserReducer from './services/student/reducer';
import DashboardUserReducer from './services/dashboard/reducer';

const rootReducer = combineReducers({
	translation: TranslationReducer,
	student: StudentUserReducer,
	dashboard: DashboardUserReducer
});

export default rootReducer;