/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ScheduleQueryResultOrg from './ScheduleQueryResultOrg';

/**
 * The ScheduleQueryResultUser model module.
 * @module model/ScheduleQueryResultUser
 * @version 1.0
 */
class ScheduleQueryResultUser {
    /**
     * Constructs a new <code>ScheduleQueryResultUser</code>.
     * @alias module:model/ScheduleQueryResultUser
     */
    constructor() { 
        
        ScheduleQueryResultUser.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ScheduleQueryResultUser</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ScheduleQueryResultUser} obj Optional instance to populate.
     * @return {module:model/ScheduleQueryResultUser} The populated <code>ScheduleQueryResultUser</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ScheduleQueryResultUser();

            if (data.hasOwnProperty('createdAt')) {
                obj['createdAt'] = ApiClient.convertToType(data['createdAt'], 'String');
            }
            if (data.hasOwnProperty('deletedAt')) {
                obj['deletedAt'] = ApiClient.convertToType(data['deletedAt'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('org')) {
                obj['org'] = ScheduleQueryResultOrg.constructFromObject(data['org']);
            }
            if (data.hasOwnProperty('role')) {
                obj['role'] = ApiClient.convertToType(data['role'], 'String');
            }
            if (data.hasOwnProperty('updatedAt')) {
                obj['updatedAt'] = ApiClient.convertToType(data['updatedAt'], 'String');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} createdAt
 */
ScheduleQueryResultUser.prototype['createdAt'] = undefined;

/**
 * @member {String} deletedAt
 */
ScheduleQueryResultUser.prototype['deletedAt'] = undefined;

/**
 * @member {Number} id
 */
ScheduleQueryResultUser.prototype['id'] = undefined;

/**
 * @member {String} name
 */
ScheduleQueryResultUser.prototype['name'] = undefined;

/**
 * @member {module:model/ScheduleQueryResultOrg} org
 */
ScheduleQueryResultUser.prototype['org'] = undefined;

/**
 * @member {String} role
 */
ScheduleQueryResultUser.prototype['role'] = undefined;

/**
 * @member {String} updatedAt
 */
ScheduleQueryResultUser.prototype['updatedAt'] = undefined;

/**
 * @member {String} username
 */
ScheduleQueryResultUser.prototype['username'] = undefined;






export default ScheduleQueryResultUser;

