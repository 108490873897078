import React, { Component } from 'react';
import cx from 'classnames';

import Answer1Icon from './answer1.svg';
import Answer2Icon from './answer2.svg';
import Answer3Icon from './answer3.svg';
import Answer4Icon from './answer4.svg';
import './CartoonAnswerButton.scss';


export default class CartoonAnswerButton extends Component {
    render() {
        const { answerIdx, answerText, selected, isAnswerSubmitted, isCorrect, ...props } = this.props;
        let icon;

        switch(answerIdx) {
        	case 0:
        		icon = Answer1Icon;
        		break;
        	case 1:
        		icon = Answer2Icon;
        		break;
        	case 2:
        		icon = Answer3Icon;
        		break;
        	case 3:
        		icon = Answer4Icon;
        		break;
        	default:
        		icon = Answer1Icon;
        		break;
        }

        return (
            <div 
            	className={cx({
            		"CartoonAnswerButton": true,
            		"selected": selected,
            		"highlight-correct": isAnswerSubmitted && isCorrect,
            		"highlight-incorrect": isAnswerSubmitted && !isCorrect,
            	})}
            	{...props}
            >
				<div className="button-icon">
					<img src={icon} alt=""/>
				</div>

				<div className="button-text">{answerText}</div>
			</div>
        );
    }
}

CartoonAnswerButton.defaultProps = {
    answerIdx: 0,
    answerText: '',
    selected: false
}