/**
 * Big Waster API
 * This is a Big Waster API.
 *
 * OpenAPI spec version: 1.0
 * Contact: terry.wong@futureimpactlab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import FeedbackQueryResult from './FeedbackQueryResult';

/**
 * The FeedbackOutputCmsFeedbacks model module.
 * @module model/FeedbackOutputCmsFeedbacks
 * @version 1.0
 */
class FeedbackOutputCmsFeedbacks {
    /**
     * Constructs a new <code>FeedbackOutputCmsFeedbacks</code>.
     * @alias module:model/FeedbackOutputCmsFeedbacks
     */
    constructor() { 
        
        FeedbackOutputCmsFeedbacks.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>FeedbackOutputCmsFeedbacks</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/FeedbackOutputCmsFeedbacks} obj Optional instance to populate.
     * @return {module:model/FeedbackOutputCmsFeedbacks} The populated <code>FeedbackOutputCmsFeedbacks</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new FeedbackOutputCmsFeedbacks();

            if (data.hasOwnProperty('feedbacks')) {
                obj['feedbacks'] = ApiClient.convertToType(data['feedbacks'], [FeedbackQueryResult]);
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<module:model/FeedbackQueryResult>} feedbacks
 */
FeedbackOutputCmsFeedbacks.prototype['feedbacks'] = undefined;






export default FeedbackOutputCmsFeedbacks;

