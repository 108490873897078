import React, { Component } from 'react';
import { DatePicker, Select } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import './FilterGroup.scss';

const { RangePicker } = DatePicker;
const Option = Select.Option;

export class FilterGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {}
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { filterList, onChange } = this.props;
        const { filters } = this.state

        if (!_.isEqual(prevProps.filterList, filterList)) {
            this.setState({ filters: {} });
        }

        if (!_.isEqual(prevState.filters, filters)) {
            onChange(filters);
        }
    }

    render() {
        const { filterList, disabled } = this.props;
        const { filters } = this.state;

        return (
            <div className="FilterGroup">
                {
                    _.map(filterList, (filter) => {
                        switch (filter.type) {
                            case 'select':
                            case 'multiSelect':
                                const options = _.map(filter.options, (option) => (<Option key={`${filter.name}-${option.value}`} value={option.value}>{option.label}</Option>));
                                return (
                                    <Select
                                        style={{ width: filter.type === 'multiSelect' ? 220 : 120 }}
                                        mode={filter.type === 'multiSelect' ? 'multiple' : 'default'}
                                        className="filter-group-filter"
                                        key={filter.name}
                                        placeholder={filter.placeholder} 
                                        dropdownMatchSelectWidth={false} 
                                        allowClear
                                        value={filters[filter.name]}
                                        onChange={(value) => this.setState({ filters: { ...filters, [filter.name]: value } })}
                                        disabled={disabled}
                                    >
                                        {options}
                                    </Select>
                                );
                            case 'dateRange':
                                return (
                                    <RangePicker
                                        className="filter-group-filter"
                                        key={filter.name}
                                        placeholder={filter.placeholder} 
                                        value={[
                                            filters[filter.name + '_start'] ? moment(filters[filter.name + '_start']) : undefined, 
                                            filters[filter.name + '_end'] ? moment(filters[filter.name + '_end']) : undefined
                                        ]}
                                        onChange={(value) => this.setState({ 
                                            filters: { 
                                                ...filters, 
                                                [filter.name + '_start']: value[0] ? value[0].startOf('day').toISOString() : undefined, 
                                                [filter.name + '_end']: value[1] ? value[1].endOf('day').toISOString() : undefined 
                                            }
                                        })}
                                        disabled={disabled}
                                    />
                                );
                            default:
                                return;
                        }
                    })
                }
            </div>
        );
    }
}

FilterGroup.defaultProps = {
    filterList: [],
    onChange: () => {}
}

export default FilterGroup;