import React, { Component } from 'react';
import _ from 'lodash';

import './SelectActionPage.scss';

export class SelectActionPage extends Component {
    render() {
        const { availableActions, onSelectAction } = this.props;
        const actionOptions = _.map(availableActions, (action) => (
        	<div key={action} className="action-option bw-btn" onClick={(e) => {onSelectAction(action)}}>
        		{action}
        	</div>
        ));

        return (
            <div className="SelectActionPage">
            	<div className="action-options-gp">
            		{actionOptions}
            	</div>
			</div>
        );
    }
}

export default SelectActionPage;